const DefaultStateLive = {
    repName: '',
    RepCode: '',
    repEmail: '',
    legalName: '',
    legalAddress: '',
    legalAddressSuite: '',
    legalCity: '',
    legalState: '',
    legalZip: '',
    legalPhone: '',
    legalFax: '',
    contactName: '',
    differentAddress: false,
    dbaName: '',
    dbaAddress: '',
    dbaAddressSuite: '',
    dbaCity: '',
    dbaState: '',
    dbaZip: '',
    // dbaPhone: '',
    // dbaFax: '',
    emailAddress: '',
    webUrl: '',
    lengthOwned: null,
    businessType: '',
    taxtpayerIDNumber: '',
    name: '',
    typeOfOwnership: '',
    monthlyVolumeAllCards: '',
    averageTicket: '',
    highTicket: '',
    percentSwiped: 0,
    percentKeyed: 0,
    ownerName: '',
    ownerTitle: '',
    ownerDob: null,
    ownerSsn: '',
    ownerAddress: '',
    ownerCity: '',
    ownerState: '',
    ownerZip: '',
    ownerPhone: '',
    ownerOwnedPercent: '',
    bankName: '',
    bankContact: '',
    bankRouting: '',
    bankAccount: '',
    pricing: '',
    ICDiscountPercent: '',
    ICDiscountPerItem: '',
    ICEbtfnsNumber: '',
    ICEbtfnsPerItem: '',
    ICEbtfnsCachBackPerItem: '',
    debit: false,
    debitDiscount: '',
    debitPerItem: '',
    TRQualified: '',
    TRQualifiedPerItem: '',
    TRMidQualified: '',
    TRMidQualifiedPerItem: '',
    TRNonQualified: '',
    TRNonQualifiedPerItem: '',
    TRCheckCard: '',
    TRCheckCardPerItem: '',
    authorizationFee: '',
    chargeBackFee: '',
    retrievalFee: '',
    monthlyMinimum: '',
    batchFee: '',
    tinReportingAnnual: '',
    achReturnFee: '',
    setupFee: '',
    monthlyServiceFee: '',
    servicePack: '',
    pci: '',
    nonPci: '',
    earlyTerminationFee: '',
    wirelessFee: '',
    wirelessFeePerItem: '',
    ddaChangeFee: '',
    /* date: new Date(), */
    productsServices: '',
    files: [],
    industry: '',
    equipment: '',
    connectivity: '',
    networkName: '',
    networkPassword: '',
    nextDayFunding: '',
    autoClose: '',
    settlementTime: (new Date()).setHours(22, 0, 0, 0),
    usingClover: '',
    usingNonCloverApps: '',
    cloverDashboardEmail: '',
    haveMenu: '',
    howManyItems: '',
    menuURL: '',
    menuModifiers: '',
    onlineOrdering: '',
    tips: '',
    tax: '',
    taxPercentage: '',
    nonTaxableItems: '',
    approximateCashAccepted: '',
    typeOfEquipment: '',
    shipTo: '',
    merchantOffering: '',
    eSignURL: '',
    equipmentPlaced: '',
    otherInfo: '',
    totalVolume: '',
    discoverVolume: '',
    amexVolume: '',
    readOnlyFields: false,
    purchasedReprovisioned: '',
    followingPeripherals: [],
    cloverModel: '',
    serialNumber: '',
    merchantBonus: 'No',
    cashDiscountRate: '',
    mnthVolAvg: '',
    readConsent: false,
}

const DefaultStateTest = {
    repName: 'test name',
    repEmail: 'test2@prosvit.design',
    RepCode: '0705',
    legalName: 'legal name',
    legalAddress: 'legal street',
    legalAddressSuite: '03324',
    legalCity: 'legal city',
    legalState: 'legal state',
    legalZip: '12345',
    legalPhone: '7342342344',
    legalFax: '7342342344',
    differentAddress: false,
    contactName: 'Contact Name',
    dbaName: 'dba name',
    dbaAddress: 'dba street',
    dbaAddressSuite: '0922',
    dbaCity: 'dba city',
    dbaState: 'dba state',
    dbaZip: '12345',
    // dbaPhone: '7342342344',
    // dbaFax: '7342342344',
    emailAddress: 'test@prosvit.design',
    webUrl: 'https://google.com',
    lengthOwned: new Date(),
    businessType: 'Lodging',
    taxtpayerIDNumber: '987654321',
    name: 'Name',
    typeOfOwnership: 'LLC',
    monthlyVolumeAllCards: '3123',
    averageTicket: 'das333',
    highTicket: 'high ticket',
    percentSwiped: 0,
    percentKeyed: 0,
    ownerName: 'owner name',
    ownerTitle: 'Title',
    ownerDob: new Date(),
    ownerSsn: '785469456655',
    ownerAddress: 'owner street',
    ownerCity: 'Los Angeles',
    ownerState: 'California',
    ownerZip: '12345',
    ownerPhone: '7342342344',
    ownerOwnedPercent: '50',
    bankName: 'Bank Name',
    bankContact: '7342342344',
    bankRouting: '123456789',
    bankAccount: 'Account',
    pricing: 'Interchange',
    ICDiscountPercent: '12',
    ICDiscountPerItem: '2',
    ICEbtfnsNumber: '21',
    ICEbtfnsPerItem: '12',
    ICEbtfnsCachBackPerItem: '21',
    debit: false,
    debitDiscount: '12',
    debitPerItem: '32',
    TRQualified: '21',
    TRQualifiedPerItem: '33',
    TRMidQualified: '11',
    TRMidQualifiedPerItem: '33',
    TRNonQualified: '22',
    TRNonQualifiedPerItem: '33',
    TRCheckCard: '123',
    TRCheckCardPerItem: '22',
    authorizationFee: '12',
    chargeBackFee: '12',
    retrievalFee: '12',
    monthlyMinimum: '12',
    batchFee: '12',
    tinReportingAnnual: '12',
    achReturnFee: '12',
    setupFee: '2',
    monthlyServiceFee: '2',
    servicePack: '3',
    pci: '1',
    nonPci: '2',
    earlyTerminationFee: '3',
    wirelessFee: '2',
    wirelessFeePerItem: '3',
    ddaChangeFee: '1',
    /* date: new Date(), */
    productsServices: '2',
    files: [],
    industry: 'retail',
    equipment: 'clover',
    typeOfEquipment: '',
    shipTo: 'Merchant',
    merchantOffering: '',
    eSignURL: '',
    equipmentPlaced: '',
    cashDiscount: '',
    otherInfo: '',
    totalVolume: '0',
    discoverVolume: '0',
    amexVolume: '0',
    connectivity: '',
    networkName: '',
    networkPassword: '',
    nextDayFunding: '',
    autoClose: '',
    settlementTime: (new Date()).setHours(22, 0, 0, 0),
    usingClover: '',
    usingNonCloverApps: '',
    cloverDashboardEmail: '',
    haveMenu: '',
    howManyItems: '',
    menuURL: '',
    menuModifiers: '',
    onlineOrdering: '',
    tips: '',
    tax: '',
    taxPercentage: '',
    nonTaxableItems: '',
    approximateCashAccepted: '',
    readOnlyFields: false,
    purchasedReprovisioned: '',
    followingPeripherals: [],
    cloverModel: '',
    serialNumber: '',
    merchantBonus: 'Yes',
    cashDiscountRate: '',
    mnthVolAvg: '',
    readConsent: false,
}

const DefaultState = DefaultStateTest // DefaultStateTest or DefaultStateLive

const FormReducer = (state = DefaultState, action) => {
    switch (action.type) {
        case 'UPDATE_FORM_ITEM':
            const tempObject = {}
            tempObject[action.payload.label] = action.payload.value
            if (action.payload.label == 'autoClose' && action.payload.value == 'No') {
                tempObject['settlementTime'] = (new Date()).setHours(12, 0, 0, 0)
            }
            return {
                ...state,
                ...tempObject,
            }
        case 'UPDATE_FORM_CHECKBOX':
            if (action.payload.checked) {
                state[action.payload.label].push(action.payload.value)
            } else {
                state[action.payload.label] = state[action.payload.label].filter(item => item != action.payload.value)
            }
            return {
                ...state,
            }
        case 'UPDATE_ESIGN':
            return {
                ...state,
                ...action.payload
            }
        case 'LOAD_FROM_URL':
            state.readOnlyFields = true
            return {
                ...state,
                ...action.payload
            }
        case 'CLEAR_FORM':
            return DefaultState
        case 'SWIPED':
            const enteredVal = +action.payload
            const swiped = enteredVal > 100 ? +enteredVal.toString().substring(0, 3) === 100 ? +enteredVal.toString().substring(0, 3) : +enteredVal.toString().substring(0, 2) : enteredVal
            const keyed = swiped < 100 ? 100 - swiped : 0
            return {
                ...state,
                ...{
                    percentSwiped: swiped,
                    percentKeyed: keyed
                }
            }
        case 'KEYED':
            const enterVal = +action.payload
            const keyed2 = enterVal > 100 ? +enterVal.toString().substring(0, 3) === 100 ? +enterVal.toString().substring(0, 3) : +enterVal.toString().substring(0, 2) : enterVal
            const swiped2 = keyed2 < 100 ? 100 - keyed2 : 0
            return {
                ...state,
                ...{
                    percentSwiped: swiped2,
                    percentKeyed: keyed2
                }
            }
        case 'CD_4':
            return {
                ...state,
                ...{
                    TRQualified: '3.846',
                    TRQualifiedPerItem: '0',
                    TRMidQualified: '3.846',
                    TRMidQualifiedPerItem: '0',
                    TRNonQualified: '3.846',
                    TRNonQualifiedPerItem: '0',
                    TRCheckCard: '3.846',
                    TRCheckCardPerItem: '0',
                    authorizationFee: '0',
                    chargeBackFee: '20',
                    retrievalFee: '10',
                    monthlyMinimum: '25.00',
                    batchFee: '0.00',
                    tinReportingAnnual: '0',
                    achReturnFee: '49.95',
                    setupFee: '0',
                    monthlyServiceFee: '0',
                    servicePack: '14.95',
                    pci: '0',
                    nonPci: '59.95',
                    earlyTerminationFee: '0',
                    wirelessFee: '0',
                    wirelessFeePerItem: '0',
                    ddaChangeFee: '0',
                },
                ...{
                    cashDiscount: action.type
                }
            }
        case 'CD_3_5':
            return {
                ...state,
                ...{
                    TRQualified: '3.382',
                    TRQualifiedPerItem: '0',
                    TRMidQualified: '3.382',
                    TRMidQualifiedPerItem: '0',
                    TRNonQualified: '3.382',
                    TRNonQualifiedPerItem: '0',
                    TRCheckCard: '3.382',
                    TRCheckCardPerItem: '0',
                    authorizationFee: '0',
                    chargeBackFee: '20',
                    retrievalFee: '10',
                    monthlyMinimum: '25.00',
                    batchFee: '0.00',
                    tinReportingAnnual: '0',
                    achReturnFee: '49.95',
                    setupFee: '0',
                    monthlyServiceFee: '0',
                    servicePack: '14.95',
                    pci: '0',
                    nonPci: '59.95',
                    earlyTerminationFee: '0',
                    wirelessFee: '0',
                    wirelessFeePerItem: '0',
                    ddaChangeFee: '0',
                },
                ...{
                    cashDiscount: action.type
                }
            }
        case 'CD_3':
            return {
                ...state,
                ...{
                    TRQualified: '2.913',
                    TRQualifiedPerItem: '0',
                    TRMidQualified: '2.913',
                    TRMidQualifiedPerItem: '0',
                    TRNonQualified: '2.913',
                    TRNonQualifiedPerItem: '0',
                    TRCheckCard: '2.913',
                    TRCheckCardPerItem: '0',
                    authorizationFee: '0',
                    chargeBackFee: '20',
                    retrievalFee: '10',
                    monthlyMinimum: '25.00',
                    batchFee: '0.00',
                    tinReportingAnnual: '0',
                    achReturnFee: '49.95',
                    setupFee: '0',
                    monthlyServiceFee: '0',
                    servicePack: '14.95',
                    pci: '0',
                    nonPci: '59.95',
                    earlyTerminationFee: '0',
                    wirelessFee: '0',
                    wirelessFeePerItem: '0',
                    ddaChangeFee: '0',
                },
                ...{
                    cashDiscount: action.type
                }
            }
        case 'CD_CUSTOM':
            return {
                ...state,
                ...{
                    TRQualified: '',
                    TRQualifiedPerItem: '',
                    TRMidQualified: '',
                    TRMidQualifiedPerItem: '',
                    TRNonQualified: '',
                    TRNonQualifiedPerItem: '',
                    TRCheckCard: '',
                    TRCheckCardPerItem: '',
                    authorizationFee: '',
                    chargeBackFee: '',
                    retrievalFee: '',
                    monthlyMinimum: '',
                    batchFee: '',
                    tinReportingAnnual: '',
                    achReturnFee: '',
                    setupFee: '',
                    monthlyServiceFee: '',
                    servicePack: '',
                    pci: '',
                    nonPci: '',
                    earlyTerminationFee: '',
                    wirelessFee: '',
                    wirelessFeePerItem: '',
                    ddaChangeFee: '',
                    cashDiscount: action.type
                }
            }
        case 'INTERCHANGE':
            return {
                ...state,
                ...{
                    chargeBackFee: '20',
                    retrievalFee: '10',
                    monthlyMinimum: '25',
                    tinReportingAnnual: '3.95',
                    achReturnFee: '49.95',
                    monthlyServiceFee: '10',
                    servicePack: '14.95',
                    pci: '4.95',
                    nonPci: '59.95',
                    pricing: 'Interchange'
                }
            }
        case 'TIERED':
            return {
                ...state,
                ...{
                    chargeBackFee: '20',
                    retrievalFee: '10',
                    monthlyMinimum: '25',
                    tinReportingAnnual: '3.95',
                    achReturnFee: '49.95',
                    monthlyServiceFee: '10',
                    servicePack: '14.95',
                    pci: '4.95',
                    nonPci: '59.95',
                    pricing: 'Tiered'
                }
            }
        default:
            return state
    }
}

export default FormReducer
