import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormLabel, FormControlLabel, OutlinedInput, InputLabel, Grid, RadioGroup, Radio, FormHelperText } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative'
  },
  helper: {
    position: 'absolute',
    bottom: -20,
    left: 0
  }
}))

const StepTwo = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { taxtpayerIDNumber, name, typeOfOwnership } = useSelector(state => state.Form)

  return (
    <>
      <h2>W9 Information</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='taxpayer-id'>Taxpayer Identification Number</InputLabel>
              <OutlinedInput labelWidth={245} id='taxpayer-id' value={taxtpayerIDNumber} inputProps={{ 'pattern': '^[0-9]{9}', 'title': 'Please input valid number (9 digits)' }} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'taxtpayerIDNumber', value: e.target.value } })} />
              <FormHelperText className={classes.helper}>No spaces</FormHelperText>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='name'>Name (as shown on your income tax return)</InputLabel>
              <OutlinedInput labelWidth={330} id='name' value={name} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'name', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} >
          <div className={classes.formControl}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Type of ownership</FormLabel>
              <RadioGroup row aria-label="position" name="position" value={typeOfOwnership} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'typeOfOwnership', value: e.target.value } })}>
                <FormControlLabel value="Sole Prop" control={<Radio color="primary" required />} label="Sole Prop" />
                <FormControlLabel value="LLC" control={<Radio color="primary" />} label="LLC" />
                <FormControlLabel value="Partnership" control={<Radio color="primary" required />} label="Partnership" />
                <FormControlLabel value="Non Profit" control={<Radio color="primary" required />} label="Non Profit" />
                <FormControlLabel value="Government" control={<Radio color="primary" required />} label="Government" />
                <FormControlLabel value="Corporation" control={<Radio color="primary" required />} label="Corporation" />
                <FormControlLabel value="Public Corporation" control={<Radio color="primary" required />} label="Public Corporation" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default StepTwo
