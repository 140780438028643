import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Stepper, StepButton, Step, CircularProgress, Snackbar, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography'
import StepOne from '../steps/StepOne'
import StepTwo from '../steps/StepTwo'
import StepThree from '../steps/StepThree'
import StepFour from '../steps/StepFour'
import StepFive from '../steps/StepFive'
import StepSix from '../steps/StepSix'
import StepSeven from '../steps/StepSeven'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '1800px',
    margin: '150px auto 100px',
    boxShadow: '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
    position: 'relative'
  },
  header: {
    boxShadow: '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
    padding: '24px'
  },
  title: {
    margin: 0
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'left',
  },
  form2: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'left',
    maxWidth: '700px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  formFooter: {
    padding: theme.spacing(3),
    paddingTop: '20px',
    textAlign: 'left',
  },
  stepButton: {
    cursor: 'pointer'
  }
}))

function getSteps() {
  return ['Business Information', 'W9 Information', 'Processing Data', 'Owner and Officer Information', 'Banking Information', 'Pricing', 'Finish']
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <StepOne />
    case 1:
      return <StepTwo />
    case 2:
      return <StepThree />
    case 3:
      return <StepFour />
    case 4:
      return <StepFive />
    case 5:
      return <StepSix />
    case 6:
      return <StepSeven />
    default:
      return 'Unknown step'
  }
}

const sendForm = async (data) => {
  const formData = new FormData();

  for (const name in data) {
    if (name === "files") {
      data[name].map((file) => formData.append(name + "[]", file));
    } else {
      formData.append(name, data[name]);
    }
  }

  const response = await fetch('/api/send-data', {
    method: 'POST',
    body: formData
  })

  const responseJson = await response.json()
  return await responseJson.message
}

const Form = () => {
  const classes = useStyles()
  const steps = getSteps()
  const dispatch = useDispatch()
  let history = useHistory();
  const { error, errorText, success, successText, activeStep, logged, loading, password } = useSelector(state => state.Validation)
  const FormFileds = useSelector(state => state.Form)

  const handleNext = (e) => {
    e.preventDefault();

    if (activeStep <= 5) {
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep + 1 } })
    }
  }

  const copyLink = () => {
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'successText', value: 'Link copied' } })
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: true } })
  }

  const handleBack = () => {
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep - 1 } })
  }

  const handleReset = () => {
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: 0 } })
  }

  const sendPassword = async (e) => {
    e.preventDefault()
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'loading', value: true } })
    const response = await fetch('/api/check-pass', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "pass": password
      })
    })

    const responseJson = await response.json()

    if (await responseJson.message === 'success') {
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'logged', value: true } })
    } else {
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'logged', value: false } })
    }
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'loading', value: false } })
  }

  return (
    <div className={classes.root}>
      {loading && (
        <div className="prosvit-loader">
          <CircularProgress />
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        autoHideDuration={3000}
        onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: false } })}
      >
        <Alert variant="filled" onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: false } })} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={success}
        autoHideDuration={3000}
        onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: false } })}
      >
        <Alert variant="filled" onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: false } })} severity="success">
          {successText}
        </Alert>
      </Snackbar>
      {!logged && (
        <form onSubmit={sendPassword}>
          <div className={classes.header}>
            <h1 className={classes.title}>Admin features area</h1>
          </div>
          <div className={classes.form}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel htmlFor='admin-password'>Enter password</InputLabel>
              <OutlinedInput labelWidth={125} id='admin-password' value={password} onInput={e => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'password', value: e.target.value } })} />
            </FormControl>
            <div className={classes.formFooter}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </form>
      )}
      {logged && (
        <>
          <Stepper activeStep={activeStep} alternativeLabel nonLinear className={classes.header}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton className={classes.stepButton} onClick={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: index } })}>
                  {label}
                </StepButton>
                {/* <StepLabel>{label}</StepLabel> */}
              </Step>
            ))}
          </Stepper>
          <div className='MuiStep-content'>
            {activeStep === steps.length ? (
              <div className='MuiStep-content-item'>
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <>
                <form className={classes.form} onSubmit={handleNext} noValidate>
                  {getStepContent(activeStep)}
                  <div className={classes.formFooter}>
                    {activeStep !== steps.length - 1 && (
                      <Button variant='contained' color='primary' type="submit" >
                        Next
                      </Button>
                    )}
                    <CopyToClipboard text={`${document.location.origin}?${new URLSearchParams(FormFileds)}`} onCopy={copyLink}>
                      <div className="copy-area">
                        <Button color="primary" type="button">
                          Copy Link
                        </Button>
                      </div>
                    </CopyToClipboard>

                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      back
                    </Button>
                  </div>
                </form>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Form


