import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormLabel, FormControlLabel, OutlinedInput, InputLabel, Grid, RadioGroup, Radio, Checkbox, FormHelperText } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative'
  },
  helper: {
    position: 'absolute',
    bottom: -20,
    left: 0
  },
  mb0: {
    marginBottom: 0
  }
}))

const StepOne = () => {
  let isDisabled
  const classes = useStyles()
  const dispatch = useDispatch()
  const { repName, RepCode, repEmail, legalName, legalAddress, legalAddressSuite, legalCity, legalState, legalZip, legalPhone, legalFax, dbaName, dbaAddress, dbaAddressSuite, dbaCity, dbaState, dbaZip, /* dbaPhone, dbaFax, */ emailAddress, webUrl, lengthOwned, businessType, differentAddress, industry, equipment, contactName } = useSelector(state => state.Form)

  const handleChange = (e) => {
    dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'differentAddress', value: e.target.checked } })
    isDisabled = e.target.checked
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel htmlFor='rep-name'>Rep Name</InputLabel>
              <OutlinedInput labelWidth={105} id='rep-name' value={repName} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'repName', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel htmlFor='rep-code'>Rep Code</InputLabel>
              <OutlinedInput labelWidth={105} id='rep-code' value={RepCode} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'RepCode', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel htmlFor='rep-email'>Rep Email</InputLabel>
              <OutlinedInput labelWidth={105} id='rep-email' inputProps={{ 'pattern': '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$', 'title': 'Please input valid email' }} value={repEmail} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'repEmail', value: e.target.value.toLowerCase() } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12}>
          <h2 className={classes.mb0}>Business Information</h2>
        </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.formControl}>
              <FormControl fullWidth variant='outlined' required>
                <InputLabel htmlFor='legal-name'>Legal Name</InputLabel>
                <OutlinedInput labelWidth={105} id='legal-name' value={legalName} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'legalName', value: e.target.value } })} />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.formControl}>
              <FormControl fullWidth variant='outlined' required>
                <InputLabel htmlFor='dba-name'>DBA Name</InputLabel>
                <OutlinedInput labelWidth={105} id='dba-name' value={dbaName} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaName', value: e.target.value } })} />
              </FormControl>
            </div>
          </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='contact-name'>Contact Name</InputLabel>
              <OutlinedInput labelWidth={125} id='contact-name' value={contactName} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'contactName', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
      </Grid>




      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <h3>Physical Address</h3>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='legal-address'>Address</InputLabel>
                  <OutlinedInput labelWidth={85} id='legal-address' value={legalAddress} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'legalAddress', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='legal-address-suite'>Suite</InputLabel>
                  <OutlinedInput
                    labelWidth={45}
                    id='legal-address-suite'
                    value={legalAddressSuite}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'legalAddressSuite', value: e.target.value } })}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='legal-city'>City</InputLabel>
                  <OutlinedInput labelWidth={45} id='legal-city' value={legalCity} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'legalCity', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
          </Grid>



          <Grid container spacing={3}>
            <Grid item xs={9}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='legal-state'>State</InputLabel>
                  <OutlinedInput labelWidth={55} id='legal-state' value={legalState} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'legalState', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='legal-zip'>ZIP</InputLabel>
                  <OutlinedInput inputProps={{ 'pattern': '[0-9]{5}', 'title': 'Enter valid zip code' }} labelWidth={45} id='legal-zip' value={legalZip} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'legalZip', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='legal-phone'>Business Phone Number</InputLabel>
                  <OutlinedInput type="tel" inputProps={{ 'pattern': '^[0-9]{10,11}', 'title': 'Please input valid number (10 or 11 digits)' }} labelWidth={195} id='legal-phone' value={legalPhone} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'legalPhone', value: e.target.value } })} />
                  <FormHelperText className={classes.helper}>No spaces</FormHelperText>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='legal-fax'>Business Fax Number</InputLabel>
                  <OutlinedInput type="tel" inputProps={{ 'pattern': '^[0-9]{10,11}', 'title': 'Please input valid number (10 or 11 digits)' }} labelWidth={165} id='legal-fax' value={legalFax} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'legalFax', value: e.target.value } })} />
                  <FormHelperText className={classes.helper}>No spaces</FormHelperText>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='different-address'
                      color="primary"
                      value={differentAddress}
                      checked={differentAddress}
                      onChange={handleChange}
                    />
                  }
                  label="If Mailing Address Different"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <h3>Mailing Address</h3>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required={differentAddress}>
                  <InputLabel htmlFor='dba-address'>Address</InputLabel>
                  <OutlinedInput labelWidth={85} id='dba-address' inputProps={{ 'disabled': !differentAddress }} value={differentAddress ? dbaAddress : legalAddress} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaAddress', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' >
                  <InputLabel htmlFor='dba-address-suite'>Suite</InputLabel>
                  <OutlinedInput
                    labelWidth={45}
                    id='dba-address-suite'
                    inputProps={{ 'disabled': !differentAddress }}
                    value={differentAddress ? dbaAddressSuite : legalAddressSuite}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaAddressSuite', value: e.target.value } })}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required={differentAddress}>
                  <InputLabel htmlFor='dba-city'>City</InputLabel>
                  <OutlinedInput labelWidth={45} id='dba-city' inputProps={{ 'disabled': !differentAddress }} value={differentAddress ? dbaCity : legalCity} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaCity', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
          </Grid>



          <Grid container spacing={3}>
            <Grid item xs={9}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required={differentAddress}>
                  <InputLabel htmlFor='dba-state'>State</InputLabel>
                  <OutlinedInput labelWidth={55} id='dba-state' inputProps={{ 'disabled': !differentAddress }} value={differentAddress ? dbaState : legalState} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaState', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required={differentAddress}>
                  <InputLabel htmlFor='dba-zip'>ZIP</InputLabel>
                  <OutlinedInput inputProps={{ 'pattern': '[0-9]{5}', 'title': 'Enter valid zip code', 'disabled': !differentAddress }} labelWidth={45} id='dba-zip' value={differentAddress ? dbaZip : legalZip} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaZip', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          {/* <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required={differentAddress}>
                  <InputLabel htmlFor='dba-phone'>DBA Phone</InputLabel>
                  <OutlinedInput type="tel" inputProps={{ 'pattern': '[789][0-9]{9}', 'title': 'Please input valid number', 'disabled': !differentAddress }} labelWidth={95} id='dba-phone' value={differentAddress ? dbaPhone : legalPhone} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaPhone', value: e.target.value } })} />
                  <FormHelperText className={classes.helper}>No spaces</FormHelperText>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required={differentAddress}>
                  <InputLabel htmlFor='dba-fax'>DBA Fax</InputLabel>
                  <OutlinedInput type="tel" inputProps={{ 'pattern': '[789][0-9]{9}', 'title': 'Please input valid number', 'disabled': !differentAddress }} labelWidth={75} id='dba-fax' value={differentAddress ? dbaFax : legalFax} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaFax', value: e.target.value } })} />
                  <FormHelperText className={classes.helper}>No spaces</FormHelperText>
                </FormControl>
              </div>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='email-address'>Email Address</InputLabel>
              <OutlinedInput inputProps={{ 'pattern': '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$', 'title': 'Please input valid email' }} labelWidth={125} id='email-address' value={emailAddress} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'emailAddress', value: e.target.value.toLowerCase() } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel htmlFor='web-url'>Web Url</InputLabel>
              <OutlinedInput type="text" labelWidth={75} id='web-url' value={webUrl} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'webUrl', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl fullWidth variant='outlined' required>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  inputVariant="outlined"
                  required
                  variant='inline'
                  format='MM/dd/yyyy'
                  id='length-owned'
                  label='Business Start Date'
                  value={lengthOwned}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'lengthOwned', value: date } })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.formControl}>
            <FormControl component="fieldset" required>
              <FormLabel component="legend">Type of Business</FormLabel>
              <RadioGroup row aria-label="position" name="position" value={businessType} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'businessType', value: e.target.value } })}>
                <FormControlLabel value="Retail" control={<Radio color="primary" required />} label="Retail" />
                <FormControlLabel value="Restaurant" control={<Radio color="primary" required />} label="Restaurant" />
                <FormControlLabel value="Moto" control={<Radio color="primary" required />} label="Moto" />
                <FormControlLabel value="Internet" control={<Radio color="primary" required />} label="Internet" />
                <FormControlLabel value="Lodging" control={<Radio color="primary" required />} label="Lodging" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default StepOne
