import React, { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormLabel, Button, FormControlLabel, Grid, RadioGroup, Radio, Checkbox, InputLabel, Select, MenuItem, OutlinedInput, FormHelperText } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { DropzoneArea } from 'material-ui-dropzone'
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import SignaturePad from 'react-signature-canvas'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative'
  },
  helper: {
    position: 'absolute',
    bottom: -20,
    left: 0
  },
  mb10: {
    marginBottom: '10px'
  },
  miniTitle: {
    textAlign: 'center'
  },
  eSign: {
    width: '100%',
    height: '100%'
  },
  fc: {
    width: '100%',
  },
  signContainer: {
    width: '100 %',
    height: '250px',
    background: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '5px',
    marginBottom: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginRight: theme.spacing(2)
  },
  uploadTitle: {
    marginTop: 0,
    marginBottom: 0
  }
}))

const StepSeven = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    RepCode,
    typeOfEquipment,
    shipTo,
    merchantOffering,
    equipmentPlaced,
    eSignURL,
    dbaName,
    industry,
    equipment,
    otherInfo,
    connectivity,
    networkName,
    networkPassword,
    nextDayFunding,
    autoClose,
    settlementTime,
    usingClover,
    usingNonCloverApps,
    cloverDashboardEmail,
    haveMenu,
    howManyItems,
    menuURL,
    menuModifiers,
    onlineOrdering,
    tips,
    tax,
    taxPercentage,
    nonTaxableItems,
    approximateCashAccepted,
    purchasedReprovisioned,
    followingPeripherals,
    cloverModel,
    merchantBonus,
    serialNumber,
    cashDiscountRate,
    mnthVolAvg,
    readOnlyFields,
    readConsent,
  } = useSelector(state => state.Form)
  const { logged } = useSelector(state => state.Validation)
  const [cloverEnabled, setCloverEnabled] = useState(true)
  const [retailEnabled, setRetailEnabled] = useState(true)

  function handleDropzoneChange(files) {
    dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'files', value: files } });
  }

  const eSign = useRef({})
  const clear = () => {
    eSign.current.clear()
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'successText', value: 'E-Sign cleared' } })
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: true } })
    dispatch({
      type: 'UPDATE_FORM_ITEM', payload: {
        label: 'eSignURL', value: ''
      }
    })
  }
  const saveESign = () => {
    if (eSign.current.isEmpty()) {
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'successText', value: 'E-Sign cleared' } })
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: true } })
      dispatch({
        type: 'UPDATE_FORM_ITEM', payload: {
          label: 'eSignURL', value: ''
        }
      })
    } else {
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'successText', value: 'E-Sign saved' } })
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: true } })
      dispatch({
        type: 'UPDATE_FORM_ITEM', payload: {
          label: 'eSignURL', value: eSign.current.getTrimmedCanvas()
            .toDataURL('image/png')
        }
      })
    }
  }

  const freePlacementEnabled = () => (equipment === 'virtual_terminal' || equipment === 'mobile' || equipment === 'ecommerce') ? false : true


  // const cloverDisabled = () => (equipment === 'terminal') ? true : false

  const changeEquipment = (e) => {
    if ((e.target.value === 'terminal')) {
      setCloverEnabled(false)
      setRetailEnabled(true)
    } else if (e.target.value === 'clover') {
      setCloverEnabled(true)
      setRetailEnabled(false)
    } else {
      setCloverEnabled(true)
      setRetailEnabled(true)
    }

    dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'typeOfEquipment', value: '' } })
    dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'equipment', value: e.target.value } })
  }

  return (
    <>
      <h2>Finish</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='industry'>Industry</InputLabel>
              <Select
                id='industry'
                labelWidth={75}
                value={industry}
                onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'industry', value: e.target.value } })}
              >
                <MenuItem value='card_not_present'>Card Not Present</MenuItem>
                <MenuItem value='retail'>Retail</MenuItem>
                <MenuItem value='retail_with_tips'>Retail with tips</MenuItem>
                <MenuItem value='restaurant'>Restaurant</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='equipment'>Equipment</InputLabel>
              <Select
                id='equipment'
                labelWidth={100}
                value={equipment}
                onChange={e => changeEquipment(e)}
              >
                <MenuItem value='terminal'>Terminal</MenuItem>
                <MenuItem value='clover'>Clover</MenuItem>
                <MenuItem value='virtual_terminal'>Virtual Terminal</MenuItem>
                <MenuItem value='mobile'>Mobile</MenuItem>
                <MenuItem value='ecommerce'>Ecommerce</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>

        {freePlacementEnabled() && (
          <>
            <Grid item xs={12} >
              <h2 className={classes.uploadTitle}>Business</h2>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='buss-name'>Business Name</InputLabel>
                  <OutlinedInput labelWidth={125} id='buss-name' value={dbaName} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'dbaName', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={(connectivity === 'WIFI') ? 4 : 12}>
              <div className={classes.formControl}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">Connectivity</FormLabel>
                  <RadioGroup row aria-label="connectivity" name="connectivity" value={connectivity} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'connectivity', value: e.target.value } })}>
                    <FormControlLabel value="Ethernet" control={<Radio color="primary" />} label="Ethernet" />
                    <FormControlLabel value="WIFI" control={<Radio color="primary" />} label="WIFI" />
                    <FormControlLabel value="GPRS" control={<Radio color="primary" />} label="GPRS" />
                    <FormControlLabel value="DIAL UP" control={<Radio color="primary" />} label="DIAL UP" />
                    <FormControlLabel value="MOBILE" control={<Radio color="primary" />} label="MOBILE" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            {connectivity === 'WIFI' && (
              <>
                <Grid item xs={12} md={2}>
                  <h3 className={classes.miniTitle}>If WIFI</h3>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={classes.formControl}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel htmlFor='network-name'>Network Name</InputLabel>
                      <OutlinedInput labelWidth={125} id='network-name' value={networkName} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'networkName', value: e.target.value } })} />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={classes.formControl}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel htmlFor='network-password'>Network Password</InputLabel>
                      <OutlinedInput labelWidth={145} id='network-password' value={networkPassword} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'networkPassword', value: e.target.value } })} />
                    </FormControl>
                  </div>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormLabel component="legend" className={classes.mb10}>Next Day Funding</FormLabel>
                <span className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}>Omaha 4pm PST cutoff </span>
                <span className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}> | </span>
                <span className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}> North 7pm PST cutoff</span>
              </div>
            </Grid>

            <Grid item xs={12} md={4} lg={2}>
              <div className={classes.formControl}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">Auto Close?</FormLabel>
                  <RadioGroup row aria-label="auto-close" name="auto-close" value={autoClose} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'autoClose', value: e.target.value } })}>
                    <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={4} lg={6}>
              <div className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <FormControl fullWidth variant='outlined'>
                    <KeyboardTimePicker
                      required={autoClose == 'Yes' ? true : false}
                      autoOk
                      disableToolbar
                      inputVariant="outlined"
                      variant='inline'
                      id='settlement-time'
                      label='Settlement Time'
                      value={settlementTime}
                      disabled={autoClose == 'No' ? true : false}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={time => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'settlementTime', value: time } })}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </FormControl>
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            {(RepCode === '0705' || RepCode === '0729' || RepCode === '0736' || RepCode === '0755') && (
              <>
                <Grid item xs={12}>
                  <div className={classes.formControl}>
                    <FormControl component="fieldset" required>
                      <FormLabel component="legend">Merchant Bonus?</FormLabel>
                      <RadioGroup row aria-label="merchant-bonus" name="merchant-bonus" value={merchantBonus} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'merchantBonus', value: e.target.value } })}>
                        <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
                {merchantBonus === 'Yes' && (
                  <>
                    <Grid item xs={12} md={4}>
                      <div className={classes.formControl}>
                        <FormControl fullWidth variant='outlined' required>
                          <InputLabel htmlFor='mnth-vol-avg'>3 Mnth Vol Avg.</InputLabel>
                          <OutlinedInput labelWidth={125} id='mnth-vol-avg' value={mnthVolAvg} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'mnthVolAvg', value: e.target.value } })} />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div className={classes.formControl}>
                        <FormControl component='fieldset' required>
                          <FormLabel component='legend'>Cash Discount Rate</FormLabel>
                          <RadioGroup row aria-label='cash-discount-rate' name='cash-discount-rate' value={cashDiscountRate} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'cashDiscountRate', value: e.target.value } })}>
                            <FormControlLabel disabled={readOnlyFields} value='CD_4' control={<Radio color='primary' required />} label='4%' />
                            <FormControlLabel disabled={readOnlyFields} value='CD_3_5' control={<Radio color='primary' required />} label='3.5%' />
                            <FormControlLabel disabled={readOnlyFields} value='CD_3' control={<Radio color='primary' required />} label='3%' />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid item xs={12} >
              <h2 className={classes.uploadTitle}>Cash Discount</h2>
            </Grid>

            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tips</FormLabel>
                  <RadioGroup row aria-label="tips" name="tips" value={tips} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'tips', value: e.target.value } })}>
                    <FormControlLabel value="No Tips" control={<Radio color="primary" />} label="No Tips" />
                    <FormControlLabel value="Tips on Sale" control={<Radio color="primary" />} label="Tips on Sale" />
                    <FormControlLabel value="Tip Line & Adjust Later" control={<Radio color="primary" />} label="Tip Line & Adjust Later" />
                  </RadioGroup>
                  <FormHelperText className={classes.helper}>Tip Line & Adjust doesn’t not collect the Non Cash Adjustment on Tip Amount</FormHelperText>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Taxes</FormLabel>
                  <RadioGroup row aria-label="taxes" name="taxes" value={tax} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'tax', value: e.target.value } })}>
                    <FormControlLabel value="Tax included in sale price" control={<Radio color="primary" />} label="Tax included in sale price" />
                    <FormControlLabel value="Tax is calculated in POS" control={<Radio color="primary" />} label="Tax is calculated in POS" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={1} >
              <h3 className={classes.miniTitle}>If in POS</h3>
            </Grid>

            <Grid item xs={12} md={3} >
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='tax-percentage'>Tax Percentage</InputLabel>
                  <OutlinedInput labelWidth={125} id='tax-percentage' value={taxPercentage} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'taxPercentage', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.formControl}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Do you have non-taxable Items?</FormLabel>
                  <RadioGroup row aria-label="non-taxable-items" name="non-taxable-items" value={nonTaxableItems} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'nonTaxableItems', value: e.target.value } })}>
                    <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='approximate-of-cash-accepted'>Approximate % of cash accepted?</InputLabel>
                  <OutlinedInput labelWidth={250} id='approximate-of-cash-accepted' value={approximateCashAccepted} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'approximateCashAccepted', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>

            {equipment === 'clover' && (
              <>
                <Grid item xs={12} >
                  <h2 className={classes.uploadTitle}>Clover</h2>
                </Grid>

                <Grid item xs={12} md={4} lg={(usingClover === 'Yes') ? 3 : 8}>
                  <div className={classes.formControl}>
                    <FormControl component="fieldset" required>
                      <FormLabel component="legend">Are you Already Using Clover</FormLabel>
                      <RadioGroup row aria-label="using-clover" name="using-clover" value={usingClover} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'usingClover', value: e.target.value } })}>
                        <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>

                {(usingClover === 'Yes') && (
                  <Grid item xs={12} md={4} lg={5}>
                    <div className={classes.formControl}>
                      <FormControl fullWidth variant='outlined' required>
                        <InputLabel htmlFor='using-non-clover-apps'>List all Clover apps used by business</InputLabel>
                        <OutlinedInput labelWidth={270} id='using-non-clover-apps' value={usingNonCloverApps} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'usingNonCloverApps', value: e.target.value } })} />
                      </FormControl>
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} md={4} >
                  <div className={classes.formControl}>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel htmlFor='clover-dashboard-email'>Email address you want associated with your Clover Dashboard</InputLabel>
                      <OutlinedInput labelWidth={460} id='clover-dashboard-email' value={cloverDashboardEmail} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'cloverDashboardEmail', value: e.target.value } })} />
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={4} lg={(haveMenu === 'Yes') ? 3 : 12}>
                  <div className={classes.formControl}>
                    <FormControl component="fieldset" required>
                      <FormLabel component="legend">Do you have a Menu/Inventory?</FormLabel>
                      <RadioGroup row aria-label="have-menu" name="have-menu" value={haveMenu} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'haveMenu', value: e.target.value } })}>
                        <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={classes.helper}>Clover has a 15K Max on Items & 1K Max per category</FormHelperText>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={6} >
                  <div className={classes.formControl}>
                    <FormControl component="fieldset" required>
                      <FormLabel component="legend">Do you use Inventory or Menu Modifiers?</FormLabel>
                      <RadioGroup row aria-label="menu-modifiers" name="menu-modifiers" value={menuModifiers} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'menuModifiers', value: e.target.value } })}>
                        <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={classes.helper}>Menu/Inventory Modifiers are only available on the $49.95 clover plans.</FormHelperText>
                      <FormHelperText className={classes.helper}>With the $14.95 plan you get access to the menu/inventory but no modifiers.</FormHelperText>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={6} >
                  <div className={classes.formControl}>
                    <FormControl component="fieldset" required>
                      <FormLabel component="legend">Do you require Online Ordering?</FormLabel>
                      <RadioGroup row aria-label="online-ordering" name="online-ordering" value={onlineOrdering} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'onlineOrdering', value: e.target.value } })}>
                        <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                      </RadioGroup>
                      <FormHelperText className={classes.helper}>$49.95 Service Plan is required</FormHelperText>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <div className={classes.formControl}>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel htmlFor='clover-model'>Clover Model</InputLabel>
                      <Select
                        id='clover-model'
                        labelWidth={110}
                        value={cloverModel}
                        onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'cloverModel', value: e.target.value } })}
                      >
                        <MenuItem value='Go'>Go</MenuItem>
                        <MenuItem value='Flex'>Flex</MenuItem>
                        <MenuItem value='Mini'>Mini</MenuItem>
                        <MenuItem value='Station Solo'>Station Solo</MenuItem>
                        <MenuItem value='Station Duo'>Station Duo</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>

              </>
            )}
          </>
        )}

        {freePlacementEnabled() && (
          <Grid item xs={12} >
            <div className={classes.formControl}>
              <FormControl component="fieldset" required>
                <FormLabel component="legend">Is Equipment Being Placed?</FormLabel>
                <RadioGroup row aria-label="equipment-placed" name="equipment-placed" value={equipmentPlaced} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'equipmentPlaced', value: e.target.value } })}>
                  <FormControlLabel value="yes" control={<Radio color="primary" required />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio color="primary" required />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
          </Grid>
        )}

        {(equipmentPlaced === 'no' && equipment === 'clover') && (
          <>
            <Grid item xs={12} >
              <div className={classes.formControl}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Purchased or Reprovisioned</FormLabel>
                  <RadioGroup row aria-label="purchased-reprovisioned" name="purchased-reprovisioned" value={purchasedReprovisioned} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'purchasedReprovisioned', value: e.target.value } })}>
                    <FormControlLabel value="purchased" control={<Radio color="primary" required />} label="Purchased" />
                    <FormControlLabel value="reprovisioned" control={<Radio color="primary" required />} label="Reprovisioned" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            {(purchasedReprovisioned === 'reprovisioned') && (
              <Grid item xs={12} md={4} >
                <div className={classes.formControl}>
                  <FormControl fullWidth variant='outlined' required>
                    <InputLabel htmlFor='clover-serial'>Serial #</InputLabel>
                    <OutlinedInput labelWidth={70} id='clover-serial' value={serialNumber} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'serialNumber', value: e.target.value } })} />
                  </FormControl>
                </div>
              </Grid>
            )}
          </>
        )}

        {equipment === 'clover' && (
          <Grid item xs={12} >
            <div className={classes.formControl}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Are Any Of The Following Peripherals Required?</FormLabel>
                <FormControlLabel value="Cash Drawer" onChange={e => dispatch({ type: 'UPDATE_FORM_CHECKBOX', payload: { label: 'followingPeripherals', checked: e.target.checked, value: e.target.value } })} control={<Checkbox color="primary" checked={followingPeripherals.includes('Cash Drawer')} />} label="Cash Drawer" />
                <FormControlLabel value="Hand Held Scanner" onChange={e => dispatch({ type: 'UPDATE_FORM_CHECKBOX', payload: { label: 'followingPeripherals', checked: e.target.checked, value: e.target.value } })} control={<Checkbox color="primary" />} checked={followingPeripherals.includes('Hand Held Scanner')} label="Hand Held Scanner" />
                <FormControlLabel value="Hands Free Scanner" onChange={e => dispatch({ type: 'UPDATE_FORM_CHECKBOX', payload: { label: 'followingPeripherals', checked: e.target.checked, value: e.target.value } })} control={<Checkbox color="primary" />} checked={followingPeripherals.includes('Hands Free Scanner')} label="Hands Free Scanner" />
                <FormControlLabel value="Kitchen Printer" onChange={e => dispatch({ type: 'UPDATE_FORM_CHECKBOX', payload: { label: 'followingPeripherals', checked: e.target.checked, value: e.target.value } })} control={<Checkbox color="primary" />} checked={followingPeripherals.includes('Kitchen Printer')} label="Kitchen Printer" />
                <FormControlLabel value="Swivel Stand" onChange={e => dispatch({ type: 'UPDATE_FORM_CHECKBOX', payload: { label: 'followingPeripherals', checked: e.target.checked, value: e.target.value } })} control={<Checkbox color="primary" />} checked={followingPeripherals.includes('Swivel Stand')} label="Swivel Stand" />
                <FormControlLabel value="Weight Scale" onChange={e => dispatch({ type: 'UPDATE_FORM_CHECKBOX', payload: { label: 'followingPeripherals', checked: e.target.checked, value: e.target.value } })} control={<Checkbox color="primary" />} checked={followingPeripherals.includes('Weight Scale')} label="Weight Scale" />
                <FormControlLabel value="Star Thermal Kitchen Printer" onChange={e => dispatch({ type: 'UPDATE_FORM_CHECKBOX', payload: { label: 'followingPeripherals', checked: e.target.checked, value: e.target.value } })} control={<Checkbox color="primary" />} checked={followingPeripherals.includes('Star Thermal Kitchen Printer')} label="Star Thermal Kitchen Printer" />
              </FormControl>
            </div>
          </Grid>
        )}

        {(equipmentPlaced === 'yes') && freePlacementEnabled() && (
          <Grid item xs={12} >
            <div className={classes.formControl}>
              <FormControl component="fieldset" required>
                <FormLabel component="legend">Type of equipment</FormLabel>
                <RadioGroup row aria-label="type-of-equipment" name="type-of-equipment" value={typeOfEquipment} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'typeOfEquipment', value: e.target.value } })}>
                  <FormControlLabel value="Retail w/EMV" control={<Radio color="primary" required disabled={!retailEnabled} />} label="Retail w/EMV" />
                  <FormControlLabel value="Restaurant w/ Tips (EMV)" control={<Radio color="primary" required disabled={!retailEnabled} />} label="Restaurant w/ Tips (EMV)" />
                  <FormControlLabel value="Retail/EMV w/Debit" control={<Radio color="primary" required disabled={!retailEnabled} />} label="Retail/EMV w/Debit" />
                  <FormControlLabel value="Retail/EMV/Debit w/Cash Back" control={<Radio color="primary" required disabled={!retailEnabled} />} label="Retail/EMV/Debit w/Cash Back" />
                  <FormControlLabel value="Clover Flex" control={<Radio color="primary" required disabled={!cloverEnabled} />} label="Clover Flex" />
                  <FormControlLabel value="Clover Mini" control={<Radio color="primary" required disabled={!cloverEnabled} />} label="Clover Mini" />
                </RadioGroup>
              </FormControl>
            </div>
          </Grid>
        )}
        <Grid item xs={12} >
          <div className={classes.formControl}>
            <FormControl component="fieldset" required>
              <FormLabel component="legend">Ship To</FormLabel>
              <RadioGroup row aria-label="ship-to" name="ship-to" value={shipTo} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'shipTo', value: e.target.value } })}>
                <FormControlLabel value="Merchant" control={<Radio color="primary" required />} label="Merchant" />
                <FormControlLabel value="Rep" control={<Radio color="primary" required />} label="Rep" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} >
          <div className={classes.formControl}>
            <FormControl component="fieldset" required>
              <FormLabel component="legend">Is Merchant Offering Buy Now Pay Later To Customers</FormLabel>
              <RadioGroup row aria-label="merchant-offering" name="merchant-offering" value={merchantOffering} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'merchantOffering', value: e.target.value } })}>
                <FormControlLabel value="Yes" control={<Radio color="primary" required />} label="Yes" />
                <FormControlLabel value="No" control={<Radio color="primary" required />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} >
          <h2 className={classes.uploadTitle}>Terms & Conditions</h2>
          <Grid item xs={12}>
            <a href='/docs/ProgramGuide.pdf' target='_blank'>Program Guide</a>
          </Grid>
          <Grid item xs={12}>
            <a href='/docs/AppTermsConditions.pdf' target='_blank'>Application Term & Conditions</a>
          </Grid>
          {equipmentPlaced === 'yes' && (
            <Grid item xs={12}>
              <a href='/docs/FreePlacementAgreement.pdf' target='_blank'>Free Placement Guides</a>
            </Grid>
          )}
          {merchantBonus === 'Yes' && (
            <Grid item xs={12}>
              <a href='/docs/SigningBonusAgreement.pdf' target='_blank'>Signing Bonus Agreement</a>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.formControl}>
            <FormControlLabel
              control={<Checkbox checked={readConsent} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'readConsent', value: e.target.checked } })} name='readConsent' color='primary' />}
              label='Read & Consent'
            />
          </div>
        </Grid>

        {(!logged && readConsent) && (
          <>
            <Grid item xs={12} >
              <h2 className={classes.uploadTitle}>Please Upload Voided Check or Bank Letter</h2>
            </Grid>
            <Grid item xs={12} md={6} >
              <FormLabel component="legend" className={classes.label}>Files</FormLabel>
              <DropzoneArea
                showFileNames
                filesLimit={99}
                maxFileSize={52428800}
                dropzoneText="Drag and Drop A File Here or Click To Take Photo"
                onChange={handleDropzoneChange.bind(this)}
              />
            </Grid>
            <Grid item xs={12} md={6} >
              <FormControl component="fieldset" className={classes.fc}>
                <FormLabel component="legend" className={classes.label}>E-Sign</FormLabel>
                <div className={classes.signContainer}>
                  <SignaturePad
                    canvasProps={{ className: classes.eSign }} ref={eSign} onEnd={saveESign} />
                </div>
                <div>
                  <Button type="button" onClick={saveESign} variant="contained" color="primary" className={classes.button}>
                    Save E-Sign
                  </Button>
                  <Button variant="contained" color="secondary" type="button" onClick={clear} className={classes.button}>
                    Clear E-Sign
                  </Button>
                </div>
              </FormControl>
            </Grid>


            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='other-info'>Other Info</InputLabel>
                  <OutlinedInput multiline
                    rows={4} type="text" labelWidth={75} id='other-info' value={otherInfo} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'otherInfo', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default StepSeven
