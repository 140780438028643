import { combineReducers } from 'redux'
import FormReducer from './form/FormReducer'
import FormValidationReducer from './form/ValidationReducer'

const RootReducer = combineReducers({ 
  Form: FormReducer,
  Validation: FormValidationReducer
})

export default RootReducer
