import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto'
import './index.css';
import CssBaseline from '@material-ui/core/CssBaseline'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import Store from './Store'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={Store}>
        <CssBaseline />
        <App />
      </Provider>
    </BrowserRouter>,
  /* </React.StrictMode>, */
  document.getElementById('root')
);

reportWebVitals();



