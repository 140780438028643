import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, OutlinedInput, InputLabel, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative'
  },
}))

const StepFive = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { bankName, bankContact, bankRouting, bankAccount } = useSelector(state => state.Form)

  return (
    <>
      <h2>Banking information</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} >
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='bank-name'>Bank Name</InputLabel>
              <OutlinedInput labelWidth={105} id='bank-name' value={bankName} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'bankName', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel htmlFor='bank-contact'>Contact</InputLabel>
              <OutlinedInput type="text" labelWidth={75} id='bank-contact' value={bankContact} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'bankContact', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='bank-routing'>Routing</InputLabel>
              <OutlinedInput type="text" labelWidth={75} id='bank-routing' inputProps={{ 'pattern': '^[0-9]{9}', 'title': 'Please input valid number (9 digits)' }} value={bankRouting} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'bankRouting', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} >
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='bank-account'>Account</InputLabel>
              <OutlinedInput type="text" labelWidth={75} id='bank-account' value={bankAccount} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'bankAccount', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default StepFive
